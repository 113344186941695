export const initialState = {
  data: {},
  useridMaps: {},
  userFullNameMaps: {},
	isLoading: false,
	isLoaded: false,
	errorMessage: undefined
};

export default initialState;
