/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { createPromiseRequest } from '../../api.util';
import { hasAccessToken } from '../../../../../scanline/utils/token';

export const listPmsAndCts = (customers) => {
	const url = '/pmsandcts';

	if (hasAccessToken()) {
		const fetch = createPromiseRequest({
			url,
      method: 'POST',
      data: { customers }
		});

		return fetch();
	}
	return Promise.reject(new Error('Missing token'));
};
