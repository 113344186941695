/* eslint-disable import/no-cycle */
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { set, setIsLoadingLoaded, setErrorMessage, clear } from './actions';
import { initialState } from './initialState';
import {
	selectIsLoading,
	selectIsLoaded,
	selectErrorMessage
} from './selectors';

const isDefined = v => v !== undefined && v !== null;

const createUseridMaps = (prev = {}, newData) => {
  const clone = { ...prev };
  const customers = Object.keys(newData);
  customers.forEach(c => {
    clone[c] = clone[c] || { pms: {}, cts: {} }
  })

  customers.forEach(c => {
    const pms = newData[c]?.pms || [];
    const cts = newData[c]?.cts || [];

    pms.forEach(pm => {
      if (pm.userid && pm.userid !== 'null') { 
        clone[c].pms[pm.userid] = true;
      }
    })

    cts.forEach(ct => {
      if (ct.userid && ct.userid !== 'null') { 
        clone[c].cts[ct.userid] = true;
      }
    })
  });

  return clone;
};

const createUserFullNameMaps = (prev = {}, newData) => {
  const clone = { ...prev };
  const customers = Object.keys(newData);
  customers.forEach(c => {
    clone[c] = clone[c] || { pms: {}, cts: {} }
  })

  customers.forEach(c => {
    const pms = newData[c]?.pms || [];
    const cts = newData[c]?.cts || [];

    pms.forEach(pm => {
      if (pm.fullName && pm.fullName !== 'null') { 
        clone[c].pms[pm.fullName] = true;
      }
    })

    cts.forEach(ct => {
      if (ct.fullName && ct.fullName !== 'null') { 
        clone[c].cts[ct.fullName] = true;
      }
    })
  });

  return clone;
};

// ************
// PARTS
// ************
const reducerParts = {
  [clear]: () => {
		return _.cloneDeep(initialState);
	},

  [set]: (state, { payload: { data } }) => {
		return {
			...state,
      data: {
        ...state.data,
        ...data
      },
      useridMaps: createUseridMaps(state.useridMaps, data),
      userFullNameMaps: createUserFullNameMaps(state.userFullNameMaps, data),
		};
	},

	[setIsLoadingLoaded]: (
		state,
		{ payload: { isLoading: newIsLoading, isLoaded: newIsLoaded } }
	) => {
		const isLoading = selectIsLoading(state);
		const isLoaded = selectIsLoaded(state);

		const CHANGED = {
			isLoading: isDefined(newIsLoading) && newIsLoading !== isLoading,
			isLoaded: isDefined(newIsLoaded) && newIsLoaded !== isLoaded
		};

		if (!CHANGED.isLoaded && !CHANGED.isLoading) {
			return state;
		}

		return {
			...state,
      isLoading: CHANGED.isLoading ? newIsLoading : isLoading,
      isLoaded: CHANGED.isLoaded ? newIsLoaded : isLoaded
    };
	},

	[setErrorMessage]: (
		state,
		{ payload: { errorMessage: newErrorMessage } }
	) => {
		const errorMessage = selectErrorMessage(state);

		if (newErrorMessage === errorMessage) {
			return state;
		}

		return {
			...state,
      errorMessage: newErrorMessage
		};
	}
};

export default handleActions(reducerParts, initialState);
