/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { selectIsLoading, selectData } from '../selectors';
import { set, setIsLoadingLoaded, clear } from './actions';
import { listPmsAndCts } from './api';

const thunkLoad = (customers, { preventClear = false } = {}) => dispatch => {
  if (!preventClear) { 
    dispatch(clear());
  }
	dispatch(
		setIsLoadingLoaded({
			isLoading: true,
			isLoaded: false
		})
	);
  listPmsAndCts(customers).then((data) => {
		dispatch(set({ data }));
		dispatch(
			setIsLoadingLoaded({
				isLoading: false,
				isLoaded: true
			})
		);
	});
};

const thunkLoadIfNotLoaded = (customers) => (dispatch, getState) => {
	const isLoading = selectIsLoading(getState());
  if (!isLoading) {
    const data = selectData(getState());
    const _customers = customers.filter(c => !data[c]);
    if (_customers?.length) { 
      dispatch(thunkLoad(_customers, { preventClear: true }));
    }
	}
};

export { thunkLoad, thunkLoadIfNotLoaded };
